<template>
  <div>
    <HeaderPanel
      title="Log Detail"
      :filter="filter"
      placeholder="Search Shop Name"
      @sidebar="sidebarFilter"
      :hasDropdown="false"
      :hideCreate="false"
      routerPath=""
    />
    <div class="bg-white border-red p-2 p-lg-3 mt-3">
      <b-table
        responsive
        striped
        hover
        :fields="fields"
        :items="items"
        :busy="isBusy"
        show-empty
        @row-clicked="handleTableClick()"
        empty-text="No matching records found"
        class="mt-3"
      >
        <template v-slot:cell(create_date)="{ item }">
          <div>{{ $moment(item.create_date).format("DD MMM YYYY") }}</div>
          <small>{{ $moment(item.create_date).format("HH:mm:ss") }}</small>
        </template>
      </b-table>
      <Pagination
        @handleChangeTake="handleChangeTake"
        :pageOptions="pageOptions"
        :filter="filter"
        :rows="rows"
        @pagination="pagination"
      />
    </div>
    <SideBarFilter
      ref="sidebarFilter"
      :filter="filter"
      placeholder="Search Staff ID, Name"
      :hideStatusFilter="false"
      @searchAll="getMarketPlace"
      @clearFilter="clearFilter"
    >
      <template v-slot:filter-option>
        <div class="my-2">
          <div class="font-weight-bold mb-2">From</div>
          <div class="input-container">
            <datetime
              type="date"
              class="date-picker"
              placeholder="DD/MM/YYYY"
              format="dd/MM/yyyy"
              value-zone="local"
              ref="DateStart"
              v-model="filter.start_date"
            >
            </datetime>
            <div
              :class="'icon-primary text-right'"
              @click="$refs.DateStart.isOpen = true"
            >
              <font-awesome-icon
                icon="calendar-alt"
                :class="'cursor-default color-primary'"
                color="#B41BB4"
              />
            </div>
          </div>
        </div>
        <div class="mb-2">
          <div class="font-weight-bold mb-2">To</div>
          <div class="input-container">
            <datetime
              type="date"
              class="date-picker"
              placeholder="DD/MM/YYYY"
              format="dd/MM/yyyy"
              value-zone="local"
              ref="DateEnd"
              v-model="filter.start_date"
            >
            </datetime>
            <div
              :class="'icon-primary text-right'"
              @click="$refs.DateEnd.isOpen = true"
            >
              <font-awesome-icon
                icon="calendar-alt"
                :class="'cursor-default color-primary'"
                color="#B41BB4"
              />
            </div>
          </div>
        </div>
        <b-form-group style="margin-bottom: 0.5rem">
          <template #label>
            <b class="mb-2 f-16">Shop Type:</b><br />
            <b-form-checkbox
              v-model="shopping_SelectedAll"
              aria-describedby="flavours"
              aria-controls="flavours"
              class="mt-2"
              @change="ShoppingToggleAll"
            >
              All
            </b-form-checkbox>
          </template>

          <template v-slot="{ ariaDescribedby }">
            <b-form-checkbox-group
              v-model="filter.shopping_channel"
              :options="shopping"
              textField="name"
              valueField="id"
              :aria-describedby="ariaDescribedby"
              class="ml-4"
              stacked
            ></b-form-checkbox-group>
          </template>
        </b-form-group>
        <InputSelect
          title="Shop Name"
          name="ShopName"
          class="mb-2"
          :options="[]"
          valueField="id"
          textField="name"
        />
        <InputSelect
          title="Methods"
          name="Method"
          class="mb-2"
          :options="[]"
          valueField="id"
          textField="name"
        />
      </template>
    </SideBarFilter>
    <FooterAction routePath="/setting" @submit="saveForm()" />
    <b-modal
      id="modal-market-place"
      header-class="modal-header-marketplace py-2"
    >
      <template #modal-title> Connect New Marketplace </template>
      <div class="market-place-container">
        <img
          class="market-place-image-logo"
          v-for="(image, n) of image_url"
          :src="image"
          alt="image market place logo"
          :key="n"
        />
      </div>
      <template #modal-footer>
        <div class="d-flex justify-content-between w-100">
          <b-button
            class="btn-cancel btn-width"
            @click="$bvModal.hide('modal-market-place')"
            >Cancel</b-button
          >

          <b-button class="main-color btn-save" @click="$emit('submit')">
            Save
          </b-button>
        </div>
      </template>
    </b-modal>
    <ModalLoading ref="modalLoading" />
  </div>
</template>
<script>
import ModalLoading from "@/components/modal/ModalLoading";
import Pagination from "@/components/Pagination";
import HeaderPanel from "@/components/HeaderPanel";
import SideBarFilter from "@/components/SideBarFilter";
import InputSelect from "@/components/inputs/InputSelect";
export default {
  name: "Detail",
  components: {
    ModalLoading,
    Pagination,
    HeaderPanel,
    SideBarFilter,
    InputSelect,
  },
  data() {
    return {
      shopping: [{ name: "Shopee", id: "1" }],
      image_url: [
        "https://aukey.co.th/wp-content/uploads/2020/03/Shopee-logo.png",
        "https://www.blognone.com/sites/default/files/externals/8ad56f147f69abe1d1f8c212baa8ad0f.jpg",
        "https://www.mindphp.com/images/knowledge/line-shop.png",
      ],
      shopping_Selected: [],
      shopping_SelectedAll: false,
      shopping_Indeterminate: false,
      status: [
        { name: "Connected", id: 1 },
        { name: "Not Connected", id: 0 },
      ],
      Status_Selected: [],
      Status_SelectedAll: false,
      Status_Indeterminate: false,
      fields: [
        {
          key: "create_date",
          label: "Date / Time",
          tdClass: "text-center",
          thStyle: "width:10%",
        },
        {
          key: "shop_name",
          label: "Shop Name",
        },
        {
          key: "shop_type",
          label: "Shop Type",
          tdClass: "d-flex justify-content-center",
          thStyle: "width:20%",
        },
        {
          key: "method",
          label: "Method",
          thStyle: "width:25%",
        },
        {
          key: "URL",
          label: "URL",
          thStyle: "width:25%",
        },
      ],
      items: [],
      rows: 0,
      pageOptions: [
        { value: 10, text: "10 / page" },
        { value: 30, text: "30 / page" },
        { value: 50, text: "50 / page" },
        { value: 100, text: "100 / page" },
      ],
      filter: {
        search: null,
        page: 1,
        take: 10,
        shopping_channel: [],
        status: [],
      },
      isBusy: false,
    };
  },
  mounted() {},

  created() {
    this.getMarketPlace();
  },
  watch: {
    "filter.shopping_channel"(newValue, oldValue) {
      if (newValue.length === 0) {
        this.shopping_Indeterminate = false;
        this.shopping_SelectedAll = false;
      } else if (newValue.length === this.shopping.length) {
        this.shopping_Indeterminate = false;
        this.shopping_SelectedAll = true;
      } else {
        this.shopping_Indeterminate = true;
        this.shopping_SelectedAll = false;
      }
    },
    "filter.status"(newValue, oldValue) {
      if (newValue.length === 0) {
        this.Status_Indeterminate = false;
        this.Status_SelectedAll = false;
      } else if (newValue.length === this.status.length) {
        this.Status_Indeterminate = false;
        this.Status_SelectedAll = true;
      } else {
        this.Status_Indeterminate = true;
        this.Status_SelectedAll = false;
      }
    },
  },

  methods: {
    handleTableClick() {
      this.$router.push({ name: "Log Detail", params: { LogID: "0" } });
    },
    addMarketPlace() {
      this.$bvModal.show("modal-market-place");
    },
    ShoppingToggleAll(checked) {
      this.filter.shopping_channell = checked
        ? this.shopping.map((el) => el.id).slice()
        : [];
    },
    StatusToggleAll(checked) {
      this.filter.status = checked
        ? this.status.map((el) => el.id).slice()
        : [];
    },
    sidebarFilter() {
      this.$refs.sidebarFilter.show();
    },
    handleChangeTake(value) {
      this.filter.page = 1;
      this.show1 = 1;
      this.filter.take = value;
      this.toShow = value;
      this.filter.start_date = null;
      this.filter.end_date = null;
      this.filter.activity_type = [];
    },
    pagination(page) {
      this.filter.page = page;
    },
    async getMarketPlace() {},

    async saveForm() {
      this.$refs.modalLoading.show();
      this.axios.post(`/setting/membership`, this.form).then((res) => {
        if (res.data.result) {
          this.successAlert();
          this.getMarketPlace();
        } else {
          this.errorAlert(res.data.message);
        }
        this.$refs.modalLoading.hide();
      });
    },
    clearFilter() {
      this.filter = {
        search: null,
        page: 1,
        take: 10,
        shopping_channel: [],
        status: [],
      };
    },
  },
};
</script>
<style>
.text-log {
  text-decoration: underline;
  font-size: 65%;
  color: rgb(127, 143, 198);
  cursor: pointer;
}

label.title {
  color: var(--font-main-color);
  font-size: 16px;
  margin-bottom: 2px;
  font-weight: bold;
}
.market-place-image-logo {
  width: 50px;
  height: auto;
}
.f-20 {
  font-size: 20px;
}
.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #fff !important;
  background-color: #a59cfc;
}
.tabs-title-spacing {
  /* padding: 0px 15px 0 15px !important; */
  margin: 10px 0px;
  border-right: 1px solid rgba(0, 0, 0, 0.1);
}
.btn-fixed {
  min-width: 150px;
}
.my-actions {
  margin: 0 2em;
}

.right-gap {
  margin-right: auto;
}
.dot-status {
  padding: 10px;
  background: black;
  border-radius: 50%;
  margin-right: 5px;
}
.market-place-container {
  display: flex;
  padding: 1rem;
  justify-content: space-evenly;
}
.modal-header-marketplace {
  background-color: var(--primary-color);
  color: var(--font-primary-color);
}
</style>
